/// <reference lib="dom" />
import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import "@/styles/index.scss";

import Project1Image from "@/images/handoff-dashboard.png";
import Project2Image from "@/images/neighborrank.png";
import Project3Image from "@/images/cirrus.png";
import Project4Image from "@/images/capital.png";
import Project5Image from "@/images/accessibility.png";
import Project6Image from "@/images/feedbackcycles.png";

// markup
const IndexPage: React.FC<DefaultPageProps> = ({ location, data }) => {
  const pageTitle = "Sonali Zaveri | Design Leader | Portfolio";

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <main>
        <div className="hero hero--home">
          <Header active="index" />
          <div className="content-container">
            <h1>
              Hello, my name is Sonali Zaveri.
              <small>
                I am an accessibility certified product designer and front-end developer.
              </small>
            </h1>
            <p>
              I currently lead design at ideas42 ventures where we are helping
              entrepreneurs build social impact businesses that alleviate the
              poverty tax. Learn more about our work by visiting our{" "}
              <a href="https://ideas42ventures.com" target="blank">
                website
              </a>
              .
            </p>
            <div className="hero__podcast">
              <span>I was recently on the <strong><a href="https://link.chtbl.com/NvazwHjY">Crafted</a></strong> podcast to talk about our work at ideas42 Ventures!</span>
              <a className="hero__podcastButton" href="https://podcasts.apple.com/us/podcast/incubating-startups-that-solve-massive-social-challenges/id1649355311?i=1000603112990" target="blank">Give it a listen!</a>
            </div>
          </div>
        </div>
        <section>
          <div className="content-container">
            <div className="columns">
              <div className="column">
                <h2 style={{ color: "#9D7F12" }}>️️️✏️<br />Design</h2>
                <h3>User Focused Design</h3>
                <p>
                  I have a passion for building products that provide real value
                  for users and create positive change in the world. I believe
                  strongly that business value can only be created if user value
                  is created. Whether along side a research team or on my own,
                  learning from directly users is a key part of my design
                  process.
                </p>
                <h3>Design Leadership</h3>
                <p>
                  As a design leader, I believe in the value of process, clarity
                  and alignment in building the best creative space for my team.
                  I strive to be the coach and advocate my direct reports need.
                </p>
              </div>
              <div className="column">
                <h2 style={{ color: "#277AB9" }}>🏗️<br />Engineering</h2>
                <h3>Software Development</h3>
                <p>
                  Throughout my career I’ve held hybrid designer / developer
                  roles, allowing me to embed within engineering teams and
                  tackle problems alongside them, whether it be writing code
                  myself or building systems to enable all engineers to work
                  better.
                </p>
                <h3>Design Systems & Accessibility</h3>
                <p>
                  As a design system owner, I pair closely with engineering
                  teams to build design best practices into our systems and
                  improve our team’s front end development capability. As a
                  Certified Professional in Web Accessibility, I understand the
                  value of design systems built with accessibility in mind,
                  allowing us to build better products faster.
                </p>
              </div>
            </div>
            <div className="referral">
              <h2>Don't just take my word for it</h2>
              <p className="large">
                “I had the pleasure of directly managing Sonali in my role as VP
                of UX at OpenGov. Sonali brings a unique skill set to her
                role—she's a phenomenal designer, a sharp front-end developer,
                and a dedicated servant leader to her team. While at OpenGov,
                she single-handedly ran the design system, scaled adoption, led
                the company's extensive accessibility program, and led product
                design for several key initiatives. Her work ethic and passion
                for excellence are inspiring. Sonali is an asset to any
                organization. Most importantly, she was a delight to work with
                and I hope to work with her again soon.”
              </p>
              <p className="large">
                <strong>- Richard Baker</strong>, Former VP of UX at OpenGov
              </p>
              <p>
                <a href="https://linkedin.com/in/sdzaveri" target="blank">
                  Read additional recommendations on LinkedIn
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="work-highlights">
          <div className="content-container">
            <h2>Highlights from my work</h2>
            <p className="quiet">
              Browse through some quick spotlights on projects I’ve done.
            </p>
            <div className="project reverse-image">
              <div className="project__description">
                <h3>Building New Products & Ventures</h3>
                <p>
                  At ideas42 Ventures, we believe in bringing entrepreneurs with
                  lived experience in a problem area and building a businesses
                  alongside them. I have been through several iterations of
                  developing new businesses from an initial problem statement to
                  the launch of a first product offering. In addition to
                  designing the product, I’ve led research, product strategy,
                  and roadmap development for each new venture.
                </p>
              </div>
              <div className="project__image">
                <img
                  src={Project1Image}
                  alt="A dashboard designed for the product Handoff, meant to be used by two job partners."
                />
              </div>
            </div>
            <div className="project">
              <div className="project__description">
                <h3>Designing with Behavioral Science</h3>
                <p>
                  While design has the power to change the world, psychology has
                  the power to change people. At Opower (acquired by Oracle
                  Utilities), I harnessed the power of behavioral science to
                  reduce energy usage by utility customers. Now at ideas42
                  Ventures, I work alongside behavioral scientists to build the
                  core principles of behavioral psychology into every product we
                  build.
                </p>
              </div>
              <div className="project__image">
                <img
                  src={Project2Image}
                  alt="A dashboard designed for the product Handoff, meant to be used by two job partners."
                />
              </div>
            </div>
            <div className="project reverse-image">
              <div className="project__description">
                <h3>Innovating at Enterprise Scale</h3>
                <p>
                  At Oracle Utilities, it was clear that our on-premise products
                  used by service center representatives were difficult to use.
                  I led an effort to create a new, customer-focused, modern SaaS
                  vision for our flagship product that I presented to customers
                  at our annual conference. Our vision combined insights gained
                  from first-hand experience watching customer service
                  representatives on the job and the power of some of Oracle’s
                  existing cloud solutions.
                </p>
              </div>
              <div className="project__image">
                <img
                  src={Project3Image}
                  alt="A dashboard designed for the product Handoff, meant to be used by two job partners."
                />
              </div>
            </div>
            <div className="project">
              <div className="project__description">
                <h3>Implementing & Scaling a Design System</h3>
                <p>
                  The OpenGov design system was under utilized which left it
                  unable to scale with the fast growing start-up. I joined and
                  quickly began design and development of key missing
                  components, implemented new cross team processes, and
                  developed a roadmap to align the system with business goals.
                  My efforts increased adoption and development velocity.
                </p>
              </div>
              <div className="project__image">
                <img
                  src={Project4Image}
                  alt="A dashboard designed for the product Handoff, meant to be used by two job partners."
                />
              </div>
            </div>
            <div className="project reverse-image">
              <div className="project__description">
                <h3>Launching an Accessibility Program</h3>
                <p>
                  With the goal of turning a weakness into a competitive
                  advantage, I advocated for and received the resources to
                  launch a full scale accessibility program at the company. For
                  the program, I developed and ran training for all engineers
                  and hired dedicated accessibility testing support. This
                  critical in moving us towards our goal of ADA compliance
                  across all products, a necessity for selling to government
                  entities.
                </p>
              </div>
              <div className="project__image">
                <img
                  src={Project5Image}
                  alt="A dashboard designed for the product Handoff, meant to be used by two job partners."
                />
              </div>
            </div>
            <div className="project">
              <div className="project__description">
                <h3>Developing Company-Wide People Operations</h3>
                <p>
                  As the Operations Lead for ideas42 Ventures, I recognized the
                  value in building formalized performance management for our
                  small team. Our team wanted feedback and transparency be a
                  core component of our Studio. I implemented engagement
                  surveys, a feedback cycle program and other critical missing
                  pieces to ensure our team could thrive.
                </p>
              </div>
              <div className="project__image">
                <img
                  src={Project6Image}
                  alt="A dashboard designed for the product Handoff, meant to be used by two job partners."
                />
              </div>
            </div>
          </div>
        </section>
        <section className="work-highlights">
          <div className="content-container">
            <h2>Project Deep Dives</h2>
            <p className="quiet">Get a detailed look at some of my work.</p>
            <div className="in-progress">
              These deep dives are currently a work in progress. Please email me
              if you would like to learn more about my work.
            </div>
            <div className="deep-dives">
              <div className="deep-dives__button deep-dives__button--blue">
                <h3>Handoff</h3>
                <p>
                  Software to support communication between a job share team,
                  two individuals splitting a full time job.
                </p>
                <ul>
                  <li>Identifying a Problem, Opportunity & Solution</li>
                  <li>Defining a Product Vision & Strategy</li>
                  <li>Building & Launching an MVP</li>
                  <li>Pitching to Customers & Investors</li>
                </ul>
              </div>
              <div className="deep-dives__button deep-dives__button--green">
                <h3>Capital Design System</h3>
                <p>
                  The OpenGov Design System, built to support a large suite of products.
                </p>
                <ul>
                  <li>Architecture, Designing & Developing Components</li>
                  <li>Implementing Process Across a Suite of Products</li>
                  <li>Aligning with Business Goals</li>
                  <li>Defining a Roadmap and Managing Work in Jira</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query IndexPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <IndexPage data={data} {...props} />}
  />
);
